








































import Vue from 'vue';

import DnbIcon from '@/components/DnbIcon.vue';

interface Data {
  inputHasFocus: boolean;
  displayTooltip: boolean;
}

export default Vue.extend({
  name: 'dnb-checkbox',
  components: {
    DnbIcon,
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    id: String,
    vErrors: { type: Array as () => string[], default: () => [] },
    errorMessage: { type: String, default: 'Please check off the checkbox' },
    displayErrorMessage: { type: Boolean, default: true },
  },
  data(): Data {
    return {
      inputHasFocus: false,
      displayTooltip: false,
    };
  },
  computed: {
    hasErrors(): boolean {
      return this.vErrors.length > 0;
    },
  },

  mounted() {
    document.body.addEventListener('click', this.onClickOutside);
  },

  beforeDestroy() {
    document.body.removeEventListener('click', this.onClickOutside);
  },

  methods: {
    onFocus(event: any) {
      this.inputHasFocus = true;
      this.$emit('focus', event);
    },
    onBlur(event: any) {
      this.inputHasFocus = false;
      this.$emit('blur', event);
    },
    onClickOutside(event: MouseEvent) {
      const target = event.target as Element;

      if (this.$refs.tooltip) {
        const tooltip = this.$refs.tooltip as Element;

        if (!tooltip.contains(target)) {
          this.displayTooltip = false;
        }
      }
    },
  },
});
